import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";

export const MagnifyableImage = ({
  src = "",
  alt = "",
  className = "",
  height = "",
  width = "",
  right = true,
  imageTitle = "",
}) => {
  let hasTitle = imageTitle != "" && imageTitle != "-";
  let hasAlt = alt != "" && alt != "-";

  return (
    <Zoom wrapStyle={{ marginLeft: right ? "auto" : "" }}>
      <img
        src={src}
        alt={alt}
        title={imageTitle}
        className={className}
        height={height}
        width={width}
      />
      {(hasTitle || hasAlt) && (
        <div className="single-card-image-text">
          {hasTitle && (
            <div
              className="single-card-image-title"
              dangerouslySetInnerHTML={{ __html: imageTitle }}
            />
          )}
          {hasAlt && (
            <div
              className="single-card-image-alt"
              dangerouslySetInnerHTML={{ __html: alt }}
            />
          )}
        </div>
      )}
    </Zoom>
  );
};
