import React from "react";

import SymptomGalleryItem from "./SymptomGalleryItem/SymptomGalleryItem";

import "../../assets/scss/components/SymptomGallery/SymptomGallery.scss";

const SymptomGallery = ({ gallery, isAccordion = false }) => {
  const moreItems = gallery?.[0]?.nid && gallery.length > 6 ? "more-items" : "";

  return (
    <div className={"symptom-gallery-container symptom-gallery " + moreItems}>
      {gallery?.[0]?.nid &&
        gallery.map((item, i) => (
          <SymptomGalleryItem
            key={i}
            image={item.field_symptom_gallery_item_image?.url}
            title={item.field_symptom_gallery_item_image?.title}
            alt={item.field_symptom_gallery_item_image?.alt}
            url={item.field_url}
            zoomable={item.field_symptom_gallery_item_image?.zoomable}
            isAccordion
          />
        ))}
    </div>
  );
};

export default SymptomGallery;
