import { Box, makeStyles } from "@material-ui/core";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Api } from "../../api/Api";
import "../../assets/scss/Register/HCP/HCP.scss";
import Footer from "../../component/Footer/Footer";
import DynamicForm from "../../component/Form/DynamicForm";
import WithLoader from "../../component/WithLoader";
import { ATTEMPT_REGISTER_HCP } from "../../constants/constants";
import { FormStructure } from "../../interfaces/FormStructure";
import { GAEvent, GAEventCategory } from "../../interfaces/GAEvent";
import { PageType, RegisterHCPPage } from "../../interfaces/Pages";
import { getContent } from "../../store/PageContentActions";
import { contentState, loadingState } from "../../store/Reducer";
import { finishedLoading, loading } from "../../store/ToolBoxActions";
import mapDataToEntityFormat, { createGAEvent, getLoadingData } from "../../util/util";
import { getRegisterHCPFormStructure } from "./RegisterFormStructure";

const useStyles = makeStyles((theme) => ({
  input: {
    margin: "8px 0 8px 0",
  },
  button: {
    margin: "50px 0 0 0",
    width: (props: any) => (props.isMobile ? "325px" : "255px"),
  },
  checkBox: {
    margin: "8px 0 0 0",
  },
}));

export default function HCPRegisterForm(props) {
  const classes = useStyles({ isMobile });
  const content = useSelector(contentState);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const registerHCPPage = content[PageType.registerHCP] as RegisterHCPPage;
  const loadingPage = useSelector(loadingState).loading.includes(
    PageType.registerHCP
  );
  const [formStructure, setFormStructure] = useState<FormStructure>();
  const history = useHistory();
  const { isLoading, isSuccess } = getLoadingData(
    useSelector(loadingState),
    ATTEMPT_REGISTER_HCP
  );

  useEffect(() => {
    dispatch(getContent(PageType.registerHCP));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (registerHCPPage) {
      setFormStructure(getRegisterHCPFormStructure(registerHCPPage, t));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [registerHCPPage]);

  const handleSubmit = (data) => {
    data.name = data.firstName + " " + data.lastName;

    const { firstName, lastName, ...omittedData } = data;

    const formData = mapDataToEntityFormat(omittedData);

    dispatch(loading(ATTEMPT_REGISTER_HCP));

    Api.registerHCP(formData).then(
      (result) => {
        createGAEvent(GAEvent.FORM_SUBMISSION, GAEventCategory.HCP_REGISTERED);
        dispatch(finishedLoading(ATTEMPT_REGISTER_HCP, result.message, true));
        history.push("/hcp/thank-you");
      },
      (error) => {
        dispatch(finishedLoading(ATTEMPT_REGISTER_HCP, error.message, false));
      }
    );
  };

  return (
    <WithLoader controlLoading loading={loadingPage}>
      <Container component="main" className={"register-page hcp-register-page"}>
        <div className="register-form register-hcp-form">
          <div className="register-form-inner-container">
            <Box>
              <Typography component="h1" variant="h1" className="main-title">
                {t("register.form.hcp.pageTitle")}
              </Typography>
            </Box>
            <Box mt={14} className="register-form-items">
              {formStructure && (
                <DynamicForm
                  formStructure={formStructure}
                  onSubmit={handleSubmit}
                  classes={classes}
                  loading={isLoading}
                  success={isSuccess}
                />
              )}
            </Box>
          </div>
        </div>
      </Container>
      <Footer legal_number={content?.["register-hcp"]?.legal_number} />
    </WithLoader>
  );
}
